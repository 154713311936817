import Button, {
  ButtonProps,
  ButtonVariants,
} from '@/components/atoms/Button/Button';
import {
  AuthDialog,
  AuthDialogProps,
} from '@/components/organisms/AuthDialog/AuthDialog';
import { useAuth } from '@/hooks/useAuth';
import { BellAlertIcon, CheckCircleIcon } from '@heroicons/react/24/outline';
import { useMutation } from '@tanstack/react-query';
import {
  MouseEventHandler,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';
import { Dot } from './Dot';

export interface CheckAvailabilityButtonProps
  extends Partial<
      Pick<
        AuthDialogProps,
        'backGroundImage' | 'isAuthDialogOpen' | 'setIsAuthDialogOpen'
      >
    >,
    Pick<ButtonProps, 'size'> {
  contactedDealer: boolean;
  shadow?: boolean;
  onClick: () => Promise<void>;
  listingId: string;
}

export const CheckAvailabilityButton: React.FC<
  CheckAvailabilityButtonProps
> = ({
  contactedDealer: alreadyContactedDealer = false,
  onClick,
  backGroundImage = '',
  isAuthDialogOpen = false,
  setIsAuthDialogOpen = () => {},
  size,
  shadow = false,
  listingId,
}) => {
  const { user } = useAuth();
  const [contactedDealer, setContactedDealer] = useState(
    alreadyContactedDealer
  );

  const { mutateAsync, isPending: isSendingMessage } = useMutation({
    mutationFn: onClick,
  });

  const isSendingRef = useRef(isSendingMessage);

  const handleCheckAvailability = useCallback(async () => {
    if (user) {
      if (!isSendingMessage && !contactedDealer) {
        isSendingRef.current = true;
        await mutateAsync();
        isSendingRef.current = false;
        setContactedDealer(true);
      }
    } else {
      setIsAuthDialogOpen(true);
    }
  }, [
    mutateAsync,
    user,
    setIsAuthDialogOpen,
    isSendingMessage,
    contactedDealer,
  ]);

  const handleClickCheckAvailability: MouseEventHandler<
    HTMLButtonElement
  > = async (e) => {
    e.stopPropagation();
    e.preventDefault();
    await handleCheckAvailability();
  };

  useEffect(() => {
    if (!user && contactedDealer) {
      setContactedDealer(false);
    }
  }, [user, contactedDealer]);

  useEffect(() => {
    const checkAndSendMessage = async () => {
      const guestListingId = sessionStorage.getItem('listingId');
      if (
        guestListingId &&
        user &&
        !contactedDealer &&
        listingId === guestListingId &&
        !isSendingMessage
      ) {
        sessionStorage.removeItem('listingId');
        await handleCheckAvailability();
      }
    };

    checkAndSendMessage();
  }, [
    user,
    contactedDealer,
    listingId,
    handleCheckAvailability,
    isSendingMessage,
  ]);

  return (
    <>
      <Button
        aria-label="message"
        shadow={shadow ? 'shadow-ml m:shadow-none' : 'shadow-none'}
        size={size}
        icon={
          contactedDealer
            ? {
                icon: <CheckCircleIcon className="h-xl w-xl" />,
                position: 'left',
              }
            : undefined
        }
        variant={ButtonVariants.Tertiary}
        disabled={contactedDealer || isSendingMessage}
        loading={isSendingRef.current}
        type="button"
        onClick={handleClickCheckAvailability}
      >
        {contactedDealer ? 'Messaged' : 'Message'}
      </Button>
      <AuthDialog
        isAuthDialogOpen={isAuthDialogOpen}
        backGroundImage={backGroundImage}
        listingId={listingId}
        setIsAuthDialogOpen={setIsAuthDialogOpen}
        renderIcon={
          <>
            <BellAlertIcon className="h-xl w-xl" />
            <div className="absolute left-[54%]">{Dot}</div>
          </>
        }
        title="Check availability"
        description="Sign in or create an account to keep updated on this vehicle."
      />
    </>
  );
};
