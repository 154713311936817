import Toggle from '@/components/atoms/Toggle/Toggle';
import { useVehicleFilterSettingsContext } from '@/context/VehicleFilterSettings';
import useWindowSize from '@/hooks/useWindowSize';
import { FC } from 'react';

interface EVSavingsToggleProps {
  label?: string;
  size?: 'default' | 'small';
}
export const EVSavingsToggle: FC<EVSavingsToggleProps> = ({ label, size }) => {
  const { isMobile } = useWindowSize();
  const { settings, handleSetEvSavings } = useVehicleFilterSettingsContext();
  const evSavings = settings?.evSavings ?? false;

  const handleToggle = (value: boolean) => {
    handleSetEvSavings(value);
  };
  const toggleSize = size ?? (isMobile ? 'default' : 'small');

  return (
    <div className="flex items-center gap-s">
      {label && (
        <span className="flex whitespace-nowrap text-body1Light">{label}</span>
      )}
      <Toggle
        aria-label={`EV Savings ${evSavings ? 'on' : 'off'}`}
        checked={evSavings}
        size={toggleSize}
        onCheckedChange={handleToggle}
      />
    </div>
  );
};
