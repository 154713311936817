import { FullStoryContext } from '@/context/FullStory';
import { useContext } from 'react';

export const useFullStory = () => {
  const context = useContext(FullStoryContext);
  if (!context) {
    throw new Error(
      'useFullStoryContext must be used within a FullStoryContextProvider'
    );
  }
  return context;
};
