export const Dot = (
  <svg
    width="12"
    height="12"
    viewBox="0 0 12 12"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle
      id="Dot"
      cx="6.0002"
      cy="6.00001"
      r="5.4"
      fill="#0EDAB5"
      stroke="white"
      strokeWidth="1.2"
    />
  </svg>
);
