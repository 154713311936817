import { ToolTip } from '@/components/atoms/ToolTip/ToolTip';
import { useVehicleFilterSettingsContext } from '@/context/VehicleFilterSettings';
import {
  calculatedMonthlyPaymentFinance,
  calculatedMonthlyPaymentLease,
} from '@/lib/calculatedMonthlyPayment';
import {
  CALC_DEFAULT_MODE,
  CALC_FINANCE_INITIAL_APR,
  CALC_FINANCE_INITIAL_DOWN_PAYMENT_PERCENT,
  CALC_FINANCE_LOAN_TERM_OPTIONS,
  CALC_INITIAL_FINANCE_TERM_INDEX,
  CALC_INITIAL_LEASE_TERM_INDEX,
  CALC_LEASE_INITIAL_DOWN_PAYMENT_PERCENT,
  CALC_LEASE_INITIAL_INTEREST_RATE,
  CALC_LEASE_INITIAL_RESIDUAL_VALUE_PERCENT,
  CALC_LEASE_LOAN_TERM_OPTIONS,
  GAS_SAVINGS_INITIAL_TERM,
  PRICE_COMING_SOON,
} from '@/lib/constants';
import { InformationCircleIcon } from '@heroicons/react/20/solid';
import { CalculatorIcon } from '@heroicons/react/24/solid';
import clsx from 'clsx';
import { FC } from 'react';

export interface VehiclePriceProps {
  price: number | null;
  monthlyEstimatedPayment?: number;
  appliedIncentiveTotal: number;
  totalEstFuelSavingsPerYear: number;
  gasTerm: number;
  variant: 'VehicleCard' | 'VDPUserControlPanel' | 'VDPHeader';
  handleScrollToCalculator?: () => void;
  isListedPrice?: boolean;
}

interface VDPUserControlPanelPriceProps {
  evSavings: boolean;
  priceAfterDeductions: string;
  monthlyPrice: string;
  handleScrollToCalculator: () => void;
}
interface VehicleCardPriceProps {
  isListedPrice: boolean;
  price: number | null;
  formattedMonthlyPrice: string;
  appliedIncentiveTotal: number;
  totalEstFuelSavingsPerYear: number;
  gasTerm: number;
  displayIncentives: boolean;
  displayFuelSavings: boolean;
  variant: 'VehicleCard' | 'VDPHeader';
}

const VehicleCardPrice: React.FC<VehicleCardPriceProps> = ({
  isListedPrice,
  price,
  formattedMonthlyPrice,
  appliedIncentiveTotal,
  totalEstFuelSavingsPerYear,
  gasTerm,
  displayIncentives,
  displayFuelSavings,
  variant = 'VehicleCard',
}) => {
  let disclaimerText = (
    <p>
      The displayed price includes estimated EV Savings for maximum incentives,
      rebates, and gas savings on this EV compared to the gasoline equivalent
      car. All prices exclude taxes and fees. Prices and estimates are subject
      to change. Confirm with the seller before purchasing the vehicle.
    </p>
  );

  if (!displayIncentives) {
    // tool tip includes gas savings not incentives and rebates
    disclaimerText = (
      <p>
        The displayed price includes estimated EV Savings for gas savings on
        this EV compared to the gasoline equivalent car. All prices exclude
        taxes and fees. Prices and estimates are subject to change. Confirm with
        the seller before purchasing the vehicle.
      </p>
    );
  } else if (!displayFuelSavings) {
    // tool tip includes incentives and rebates not gas savings
    disclaimerText = (
      <p>
        The displayed price includes estimated EV Savings for maximum incentives
        and rebates. All prices exclude taxes and fees. Prices and estimates are
        subject to change. Confirm with the seller before purchasing the
        vehicle.
      </p>
    );
  }
  const fuelSavingsTotal = totalEstFuelSavingsPerYear * gasTerm;

  const roundedPrice = price
    ? Math.round(
        price -
          (displayIncentives ? appliedIncentiveTotal : 0) -
          (displayFuelSavings ? fuelSavingsTotal : 0)
      )
    : 0;

  const listPrice = !price
    ? PRICE_COMING_SOON
    : `$${roundedPrice > 0 ? roundedPrice.toLocaleString() : '0'}`;

  const showAesterix = !!price && (displayFuelSavings || displayIncentives);

  return (
    <section className="flex flex-col gap-xs">
      <div
        className={clsx('flex', {
          'flex-col': variant === 'VehicleCard',
          'flex-row items-end gap-xs ml:flex-col': variant === 'VDPHeader',
        })}
      >
        {showAesterix && (
          <p
            className={clsx('text-microLight text-neutral-800', {
              'order-2 ml:order-1': variant === 'VDPHeader',
            })}
          >
            After EV Savings applied
          </p>
        )}
        {!!price &&
          !(displayFuelSavings || displayIncentives) &&
          variant === 'VDPHeader' && (
            <p
              className={clsx('text-microLight text-neutral-800', {
                'order-2 ml:order-1': variant === 'VDPHeader',
              })}
            >
              Listed Price
            </p>
          )}
        <div
          className={clsx('flex items-center gap-s', {
            'order-1 justify-end ml:order-2': variant === 'VDPHeader',
            'justify-start': variant === 'VehicleCard',
          })}
        >
          <p
            className={clsx('text-neutral-900', {
              'text-body1Regular ml:text-h4SemiBold': variant === 'VDPHeader',
              'text-h4SemiBold': variant === 'VehicleCard',
            })}
          >
            {isListedPrice
              ? `${listPrice}${showAesterix ? '*' : ''}`
              : `${formattedMonthlyPrice}${showAesterix ? '*' : ''}`}
            {!isListedPrice && <span className="text-body1Regular">/mo</span>}
          </p>
          {showAesterix && (
            <div
              className={clsx('hidden ml:flex', {
                hidden: variant === 'VDPHeader',
                flex: variant === 'VehicleCard',
              })}
            >
              <ToolTip
                aria-label="Price savings disclosure tooltip"
                hoverElement={
                  <InformationCircleIcon className="h-[16px] w-[16px] text-neutral-600" />
                }
              >
                <div className="flex flex-col gap-2xl">
                  {disclaimerText}
                  <div>
                    {displayIncentives && (
                      <div className="flex items-center gap-xs text-microLight text-green-dark m:text-brandElectric-hover">
                        <div>Max. Incentives & Rebates</div>
                        <div className="text-microMedium">
                          -$
                          {Math.round(
                            Number(appliedIncentiveTotal)
                          ).toLocaleString()}
                        </div>
                      </div>
                    )}

                    {displayFuelSavings && (
                      <div className="flex items-center gap-xs text-microLight text-green-dark m:text-brandElectric-hover">
                        <div>Est. Gas savings </div>
                        <div>
                          <span className="text-microMedium">
                            -$
                            {Math.round(
                              Number(fuelSavingsTotal)
                            ).toLocaleString()}
                          </span>
                          <span>
                            /{gasTerm} year
                            {gasTerm === 1 ? '' : 's'}
                          </span>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </ToolTip>
            </div>
          )}
        </div>
      </div>
    </section>
  );
};

const VDPUserControlPanelPrice: FC<VDPUserControlPanelPriceProps> = ({
  evSavings,
  priceAfterDeductions,
  monthlyPrice,
  handleScrollToCalculator,
}) => {
  return (
    <>
      <div className="pb-l text-h4SemiBold text-neutral-900">
        {evSavings ? 'After Est. Savings' : 'Price'}
      </div>
      <div className="relative flex items-center justify-between gap-3xl">
        <div className="text-h3SemiBold text-neutral-900">
          {priceAfterDeductions}
          {evSavings && '*'}
        </div>
        {monthlyPrice !== 'Coming soon' && (
          <>
            <div className="absolute left-[calc(50%-0.5px)] h-[32px] w-px rounded-xsmall bg-neutral-300" />
            <button
              aria-label="go to calculator"
              onClick={handleScrollToCalculator}
            >
              <div className="flex items-center gap-s">
                <div className="text-h3SemiBold text-blue-medium">
                  {monthlyPrice}
                  {evSavings && '*'}
                  <span className="text-h4Regular">/mo</span>
                </div>
                <CalculatorIcon className="size-xl fill-blue-medium" />
              </div>
            </button>
          </>
        )}
      </div>
    </>
  );
};

export const VehiclePrice: FC<VehiclePriceProps> = ({
  price,
  // monthlyEstimatedPayment should be defined if it's being controlled externally (ie. VDP where it's calculated)
  monthlyEstimatedPayment,
  appliedIncentiveTotal,
  totalEstFuelSavingsPerYear,
  gasTerm,
  variant = 'VehicleCard',
  handleScrollToCalculator = () => {},
  isListedPrice = true,
}) => {
  const {
    settings: { evSavings, displayFuelSavings, displayIncentives },
  } = useVehicleFilterSettingsContext();

  const totalDeductions =
    (displayIncentives ? appliedIncentiveTotal : 0) +
    (displayFuelSavings ? totalEstFuelSavingsPerYear * gasTerm : 0);

  const priceAfterDeductions = price
    ? `$${Math.round(Math.max(price - totalDeductions, 0)).toLocaleString()}`
    : 'Coming soon';

  let formattedMonthlyPrice = 'Coming soon';

  if (!!price && !!monthlyEstimatedPayment) {
    formattedMonthlyPrice = `$${Math.round(Math.max(monthlyEstimatedPayment, 0)).toLocaleString()}`;
  } else if (price) {
    // calculate monthly payment based if only price is passed (ie. VehicleCards)
    // currently using Lease By Default
    const estMonthlyPrice =
      CALC_DEFAULT_MODE === 'Lease'
        ? calculatedMonthlyPaymentLease({
            apr: CALC_LEASE_INITIAL_INTEREST_RATE,
            totalAfterDeductions:
              price -
              (displayIncentives ? appliedIncentiveTotal : 0) -
              price * CALC_LEASE_INITIAL_DOWN_PAYMENT_PERCENT,
            leaseTerm: Number(
              CALC_LEASE_LOAN_TERM_OPTIONS[CALC_INITIAL_LEASE_TERM_INDEX]
            ),
            residualValue: CALC_LEASE_INITIAL_RESIDUAL_VALUE_PERCENT * price,
            fuelSavings: displayFuelSavings
              ? {
                  fuelSavingsEstimate:
                    totalEstFuelSavingsPerYear *
                    Number(GAS_SAVINGS_INITIAL_TERM),
                  timePeriod: Number(GAS_SAVINGS_INITIAL_TERM),
                }
              : undefined,
          })
        : calculatedMonthlyPaymentFinance(
            CALC_FINANCE_INITIAL_APR,
            price -
              (displayIncentives ? appliedIncentiveTotal : 0) -
              price * CALC_FINANCE_INITIAL_DOWN_PAYMENT_PERCENT,
            Number(
              CALC_FINANCE_LOAN_TERM_OPTIONS[CALC_INITIAL_FINANCE_TERM_INDEX]
            ),
            displayFuelSavings
              ? {
                  fuelSavingsEstimate:
                    totalEstFuelSavingsPerYear *
                    Number(GAS_SAVINGS_INITIAL_TERM),
                  timePeriod: Number(GAS_SAVINGS_INITIAL_TERM),
                }
              : undefined
          );

    formattedMonthlyPrice = `$${Math.round(Math.max(estMonthlyPrice, 0)).toLocaleString()}`;
  }

  if (variant === 'VDPUserControlPanel') {
    return (
      <VDPUserControlPanelPrice
        evSavings={evSavings}
        priceAfterDeductions={priceAfterDeductions}
        monthlyPrice={formattedMonthlyPrice}
        handleScrollToCalculator={handleScrollToCalculator}
      />
    );
  }

  return (
    <VehicleCardPrice
      isListedPrice={isListedPrice}
      price={price}
      formattedMonthlyPrice={formattedMonthlyPrice}
      appliedIncentiveTotal={appliedIncentiveTotal}
      totalEstFuelSavingsPerYear={totalEstFuelSavingsPerYear}
      gasTerm={gasTerm}
      displayIncentives={displayIncentives}
      displayFuelSavings={displayFuelSavings}
      variant={variant}
    />
  );
};
