import React from 'react';
import { ScComponent } from '@/types/interfaces';
import clsx from 'clsx';

export enum BadgeVariants {
  Blue = 'Blue',
  Gray = 'Gray',
  Green = 'Green',
  Yellow = 'Yellow',
  Red = 'Red',
  Dark = 'Dark',
}

export interface BadgeProps extends ScComponent {
  variant?: BadgeVariants;
  label: string;
  onClick?: () => void;
  size?: 'Regular' | 'Small';
  icon?: JSX.Element;
  iconPosition?: 'left' | 'right';
  secondaryIcon?: JSX.Element;
}

const Badge: React.FC<BadgeProps> = ({
  className = '',
  variant = BadgeVariants.Gray,
  label,
  size = 'Regular',
  onClick,
  icon = null,
  iconPosition = 'left',
  secondaryIcon = null,
}) => {
  let bgColor = '';
  let iconClass = '';
  bgColor = 'bg-neutral-200 text-neutral-800 hover:text-neutral-900';

  if (variant === BadgeVariants.Blue) {
    bgColor = 'bg-blue-light text-blue-medium hover:text-blue-dark';
    iconClass = 'text-neutral-900/40 group-hover:text-blue-medium';
  } else if (variant === BadgeVariants.Green) {
    bgColor = 'bg-green-light text-green-medium hover:text-green-dark';
  } else if (variant === BadgeVariants.Yellow) {
    bgColor = 'bg-yellow-light text-yellow-medium hover:text-yellow-dark';
  } else if (variant === BadgeVariants.Red) {
    bgColor =
      'bg-white text-red-medium hover:text-red-dark border-[1px] border-red-medium';
    iconClass = 'text-neutral-900/40 group-hover:text-red-medium/40';
  } else if (variant === BadgeVariants.Dark) {
    bgColor = 'bg-brandMidnight text-neutral-0 hover:text-brandMidnight-hover';
  }

  return (
    <div
      className={clsx(
        'group inline-flex h-fit-content cursor-pointer items-center justify-center space-x-[6px] rounded-full px-m py-xs',
        { 'flex-row-reverse': iconPosition === 'right' },
        bgColor,
        className
      )}
      onClick={onClick}
    >
      {icon && React.cloneElement(icon, { className: iconClass })}
      {icon && (
        <div className="!mr-[2px] h-[20px] border-[0.5px] border-neutral-900/10"></div>
      )}
      <span
        className={clsx('text-body2Regular', {
          'text-microMedium': size === 'Small',
        })}
      >
        {label}
      </span>
      {secondaryIcon}
    </div>
  );
};

export default Badge;
