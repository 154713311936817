import { FC, ReactNode, useRef, useState } from 'react';
import useWindowSize from '@/hooks/useWindowSize';
import Button, { ButtonVariants } from '../Button/Button';

export interface ToolTipProps {
  disabled?: boolean;
  tooltipClassName?: string;
  className?: string;
  hoverElement: ReactNode;
  children: ReactNode;
  'aria-label': string;
}

export const ToolTip: FC<ToolTipProps> = ({
  tooltipClassName = '',
  className = '',
  hoverElement,
  children,
  ...props
}) => {
  const [mobileActive, setMobileActive] = useState(false);
  const { isMobile } = useWindowSize();
  const iconRef = useRef<HTMLDivElement>(null);
  const popUpRef = useRef<HTMLDivElement>(null);
  const [position, setPosition] = useState({ top: 0, left: 0 });

  const handleMouseEnter = () => {
    if (document && iconRef.current && popUpRef.current) {
      const iconRect = iconRef.current.getBoundingClientRect();
      const popUpRect = popUpRef.current.getBoundingClientRect();
      const viewportHeight = window.innerHeight;
      const viewportWidth = window.innerWidth;

      let { top } = iconRect;

      if (top < viewportHeight / 2) {
        top = 16;
      } else {
        top = -popUpRect.height;
      }

      // move popup to the left only if it goes out of the viewport on the right
      // and the icon is on the left side of the viewport

      let left = 0;
      if (iconRect.left + popUpRect.width > viewportWidth) {
        left = viewportWidth - iconRect.x - popUpRect.width - 24;
      } else {
        left = 0;
      }
      setPosition({ top, left });
    }
  };

  const handleTooltipClick = (event: React.MouseEvent) => {
    event.preventDefault();
    setMobileActive(true);
  };

  const handleCloseClick = (event: React.MouseEvent) => {
    event.preventDefault();
    setMobileActive(false);
  };

  if (isMobile) {
    return (
      <div className={`${className}`} {...props}>
        <div
          role="button"
          aria-label="tool tip icon"
          onClick={handleTooltipClick}
        >
          {hoverElement}
        </div>

        {mobileActive && (
          <div className="fixed bottom-0 left-0 z-50 w-full bg-neutral-0 px-xl pb-[40px] pt-xl text-body2Light text-neutral-900">
            {children}
            <div className="flex w-full justify-center rounded-t-small pt-xl">
              <Button
                aria-label="close tooltip"
                onClick={handleCloseClick}
                variant={ButtonVariants.Primary}
              >
                Close
              </Button>
            </div>
          </div>
        )}
      </div>
    );
  }
  return (
    <div className={`relative ${className}`} {...props}>
      <div
        ref={iconRef}
        onMouseEnter={handleMouseEnter}
        className="peer flex cursor-pointer"
      >
        {hoverElement}
      </div>
      <div
        ref={popUpRef}
        className={`peer absolute z-10 hidden w-[300px] rounded-small bg-neutral-900 p-l text-subtitle12px text-white peer-hover:block ${tooltipClassName}`}
        aria-label={`${props['aria-label']} content`}
        style={{
          top: `${position.top}px`,
          left: `${position.left}px`,
        }}
      >
        {children}
      </div>
    </div>
  );
};
